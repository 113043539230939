import * as React from 'react'
import StandardPage from '/src/templates/StandardPage'

const Page = ({ postInfo }) => {
    const post = {
        title: 'Privacy Policy',
        content: `<div class="row"><div class="col-lg-10 offset-lg-1"><ol class="c-ordered-list">
        <li><strong>Introduction<br>
        </strong>Protect Line is a life insurance broker.&nbsp; We receive a commission from the insurer so there is no fee to you for using our broker service. We aim to provide excellent customer service when assisting with an enquiry. The information contained within this policy explains how we use and process your data. Should you have any questions around your data, please contact us using the contact details below.</li>
        <li><strong>Information collection</strong>
        <ol>
        <li>Protect Line Limited (“we” “us” “our”) is committed to protecting and respecting your privacy. We are the data controller and will process your personal data in accordance with the Data Protection Legislation (UK GDPR and Data Protection Act 2018). All data collected is confidential and is stored securely.</li>
        <li>Please read the following carefully to understand our views and practices regarding Your Data and how we will treat it.</li>
        </ol>
        </li>
        <li><strong>Visitors to our websites</strong>
        <ol>
        <li>We may collect and process personal data about you in the following circumstances:
        <ol>
        <li>When you complete the online contact forms on our websites providing us with your name, address, gender, smokers status, email address and contact number. Where you ask us to provide you with a quote for your partner too, we will collect details regarding them from you as well;</li>
        <li>Whenever you provide information to us when reporting a problem with our website, making a complaint, making an enquiry or contacting us for any other reason. If you contact us, we may keep a record of that correspondence;</li>
        <li>When you visit our websites, we will retain details such as traffic data, location data, web-logs and other communication data, and the resources that you access (see section 3.2.2 on Cookies below); and</li>
        </ol>
        </li>
        <li>We may also collect data in the following ways:
        <ol>
        <li><u><strong>IP Address<br>
        </strong></u>We may collect information about your device, including where available your Internet Protocol address, for reasons of fraud protection. We may also collect information about your device’s operating system and browser type, for system administration and to report aggregate information to our advertisers. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</li>
        <li><u><strong>Cookies<br>
        </strong></u>We use a range of different cookies on our website to improve the experience you have when visiting and to collect, analyse and improve the performance of our Services. We may also collect certain information automatically such as your location (IP address), device type and other technical information so that we can analyse and personalise our service. If you choose not to receive cookies, you may not be able to use certain features of our website.We also make use of a range of cookies which allows us to manage the effectiveness of our marketing campaigns and our website. Where we use marketing automation platforms, we may utilise cookies to track responses to our marketing emails. <p></p>
        <p>Through cookies, your personal data may be shared with third parties such as Google to serve you with personalised ads and content. This is to enhance your experience. To learn more about how Google may use your data, please see <a href="https://policies.google.com/technologies/partner-sites">Google’s Privacy &amp; Terms</a>.</p></li>
        </ol>
        </li>
        <li>We may use your personal data in order to:
        <ol>
        <li>Provide you with information, or services that you requested from us either, directly or indirectly, when submitting a request for further information around Life Insurance, Critical Illness and Income Protection products. In the event of your data being submitted from a third party, we ensure that the provider has obtained the necessary consents in order to pass your information on;</li>
        <li>Respond to an enquiry submitted via our online contact forms or if you contact us by phone or email;</li>
        <li>Allow you to participate in interactive features of our website, when you choose to do so;</li>
        <li>Ensure that content from our website is presented in the most user friendly format for you and for the device being used to access our services;</li>
        <li>Improve our websites and services;</li>
        <li>Process and deal with any complaints or enquiries made by you;</li>
        <li>Contact you for marketing purposes where you have signed up for these (see section 5 for further details)</li>
        <li>Contact you regarding job opportunities and for recruitment purposes.</li>
        </ol>
        </li>
        <li>When we process your personal data we will store a copy of your data and any appropriate details in our internal systems, to:
        <ol>
        <li>Keep a record of our interaction for future engagement with yourself or because of legal obligations to do so;</li>
        <li>Maintain your information and policy information when you are a customer (see below);</li>
        </ol>
        </li>
        </ol>
        </li>
        <li><strong>Customers<br>
        </strong>We look after your data securely and collect and use your data in the following ways:<p></p>
        <ol>
        <li>During the quoting and application process, we will collect details such as name, address, email address, contact number, date of birth, and financial information in order to provide services to customers.</li>
        <li>We will seek your consent to share personal information with an insurance provider in order to find the best policy to meet your needs. Your data will be processed by our employees in order to manage our relationship with you. We will retain personal data relating to you and your policies in accordance with the financial regulations.</li>
        <li>We will use your personal data provided to comply with our contractual and legal obligations arising from the agreements we enter into with you and share the data with product providers.</li>
        <li>We are also required by law to share our files (relating to you and your policies) with the Financial Conduct Authority (FCA) and our previous principal firms. This means:
        <ol>
        <li>For customers whose policy /policies were set up prior to July 2015, our principal firm was Sesame Ltd, Part of the Sesame Bankhall Group. For details of how they may handle your data, see their privacy policy here: <a href="https://www.sbg.co.uk/Security-and-Privacy-Policy" target="_blank" rel="noopener">https://www.sbg.co.uk/Security-and-Privacy-Policy</a>;</li>
        <li>For customers whose policy / policies were set up between July 2015 and July 2021, our principal firm was Tenet Group Ltd. For details of how they may handle your data, see their privacy policy here: <a href="https://www.tenet.co.uk/legals.html" target="_blank" rel="noopener">https://www.tenet.co.uk/legals.html</a>;</li>
        <li>For customers taking a policy or policies after 1st August 2021, this privacy policy applies. As a firm directly authorised by the Financial Conduct Authority, we will need to comply where required to share details with the FCA and with fraud prevention agencies, as required by law.</li>
        </ol>
        </li>
        <li>We will also use customer personal data for our legitimate interests including:
        <ol>
        <li>Marketing our other products and services by post, email and phone, unless you have told us not to.</li>
        <li>Obtaining and sharing your sensitive personal data with third parties, such as <a href="/insurers/">our Insurers</a>, including information about your health, ethnic origin, or criminal prosecutions such as identity verification agencies, fraud prevention agencies and other similar organisations.</li>
        <li>To invite you to participate in surveys, competitions and prize draws. The further processing of your personal data as a consequence of your participation, will be made clear to you at the data collection point when you participate in the survey, competition or prize draw.</li>
        </ol>
        </li>
        <li>Where data is transferred outside the European Economic Area (“EEA”), we will ensure that the recipient data handler is located in a country approved by the European Commission as having an adequate level of protection in place, or will ensure appropriate safeguards (such as the Standard Contractual Clauses) are in place.</li>
        <li><u><strong>Custom and look alike audiences<br>
        </strong></u>We work with Facebook, Instagram, Google and other platforms to reach out to you with adverts for related products and services we think you might be interested in. We also like to use platforms to reach out to other people who might like to use our service. We do this in two ways. In both cases, your data, along with other data, is used to create a custom audience and/or a lookalike/similar audience. Whenever an audience is shared with a platform, the data is first hashed and pseudonymised, in line with UK GDPR legislation, meaning that any data within the audience that could identify a person is replaced with an artificial identifier, and the process of transferring the data is secure. We don’t share more data than we need to for the purpose of creating the audience.<br>
        <strong>Custom Audience</strong> – A custom audience is a list of existing users of our service. From time to time, we share this with these platforms. As explained above, the data is first hashed and pseudonymised. The platform(s) uses this audience to show our existing users our adverts for our other products and services.<br>
        <strong>Lookalike/Similar Audience</strong> – A lookalike/similar audience is an audience created by the platform. From time to time, we share with platforms a list of existing users. The platform profiles this list to find and create lookalikes – i.e. other platform users who have similar characteristics to the existing users – to which it shows our adverts. As explained above, the data is first hashed and pseudonymised. At any time, you may object to such activity by opting out of receiving such information from us, please visit <a href="https://www.protectline.co.uk/contact-us/opt-out/">https://www.protectline.co.uk/contact-us/opt-out/</a> and complete the form.<br>
        You can also opt out directly with the platforms that we use on:<br>
        <strong>Facebook / Instagram</strong>: In either the Facebook or Instagram app open ‘settings &amp; privacy’ and select settings and under the Accounts Centre you can manage your Ad Preferences<br>
        <strong>Google</strong>: While logged in to your Google account, open the settings, select Privacy and security, Privacy Sandbox to manage your ad settings.</li>
        <li><u><strong>Profiling<br>
        </strong></u>We use profiling to evaluate personal aspects about you. This can include analysing your data for the purpose of marketing insight and analysis. We will also analyse your data in order to ensure that our employees have sufficiently met regulatory requirements and obligations when transacting your policy, and also for fraud prevention purposes. At the moment, we have no option to opt out of data processing in this way as the review of your data is an automated process and forms a part of our risk reduction strategy and is necessary for the provision of our services.</li>
        <li><u><strong>Website links<br>
        </strong></u>Our Site from time to time, may contain links to and from the websites of third parties. Please note that if you follow a link to any of these websites, such websites will apply different terms to the collection and privacy of your personal data and we do not accept any responsibility or liability for these policies. Please check before you submit your information to these websites.</li>
        </ol>
        </li>
        <li><strong>Lead Generation and Prospective Customers<br>
        </strong>We may receive your contact details and additional information about you from a third-party where you have supplied this information because you want to be contacted by us or are interested in one of our insurance products.<br>
        On some occasions, the third-party may be a website owned and operated by Protect Line such as <a href="https://www.comparethequote.co.uk/" target="_blank" rel="noopener">www.comparethequote.co.uk</a>.<br>
        The third-party will have gained your consent for the purposes of collecting this information and passing it to us.<br>
        When we receive your information we will use it to contact you and to discuss your needs. We may also ask you to provide additional information. When we contact you it will be made clear how we came to have your details and you will be provided with some basic privacy information including details of where to find this privacy policy.<br>
        If you decide to take up our services your information will form part of the information we need for customers (see above). If you decide not to pursue any of our services, or if it is not convenient at the time we contact you we will keep your data within our management systems for the purposes of following up with you at a more appropriate time.<br>
        You can also email: <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a></li>
        <li><strong>Suppliers and Contractors<br>
        </strong>If you are one of our suppliers we will collect the minimal information about you and your services as required to make use of your services and deal with invoices and payments for your services. Such information will be stored within our accounting package for the purposes of our accounts and will be retained accordingly.<br>
        Individual employees within our business may also retain your contact details within their email application or via business cards that you may provide to us.</li>
        <li><strong>Marketing</strong>
        <ol>
        <li>We may use customer personal data to provide you with details about our products and services which we think may be of interest.</li>
        <li>You have the right to opt-out of receiving the information detailed in section 5.1 at any time. To opt-out of receiving such information you can:
        <ol>
        <li>Complete the form on our website at <a href="/contact-us/opt-out/" target="_blank" rel="noopener">https://www.protectline.co.uk/contact-us/opt-out/</a>;</li>
        <li>Clicking the unsubscribe button contained in any such communication received; or</li>
        <li>Email us at <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a> providing us with your name and contact details.</li>
        </ol>
        </li>
        <li>Where you have subscribed to receive marketing correspondence from us, we will retain your marketing preferences indefinitely unless you unsubscribe from receiving such correspondence from us (whichever is earlier), in which case we will only keep the minimal amount of information needed to ensure we do not send you future marketing correspondence.</li>
        </ol>
        </li>
        <li><strong>Recruitment</strong>
        <ol>
        <li>We are always looking for opportunities to reach out to individuals who may be interested in working with us. To enable us to do this effectively, we make use of a range of tools.</li>
        <li>Our team will provide you with specific recruitment related privacy information as appropriate, but you should note that we may contact you via LinkedIn and as such it’s important for you to understand that we will do so for the purposes of seeing if you are seeking new employment opportunities. It will be clear to you that we are contacting you for these purposes. If you are interested and make further contact with us, then you will enter into our recruitment process which will include storing what information we have on you within our HR system, so that we can take your interest further.</li>
        <li>It is lawful for us to process your data within our systems for these purposes when you show interest in a position. Further information about our recruitment processes and how we manage your data during the recruitment process is covered in our recruitment privacy policy.</li>
        </ol>
        </li>
        <li><strong>Legal Basis for Processing Your Personal Data</strong>
        <ol>
        <li>We process your personal data for the following reasons:
        <ol>
        <li>For the purposes of arranging and facilitating your contract of insurance</li>
        <li>Where necessary for compliance with a legal obligation we are subject to; and</li>
        <li>For our legitimate interests (as described within this policy)</li>
        </ol>
        </li>
        <li>We will also process your personal data including special category data, where we have obtained your explicit consent. Should you no longer consent to us processing this data, please email us at <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a></li>
        </ol>
        </li>
        <li><strong>Disclosure of Your Data to Third Parties</strong>
        <ol>
        <li>In addition to the third parties mentioned previously in this policy, we may disclose your personal data to IT software and storage providers that store data on our behalf.&nbsp; We may also disclose your personal and/ or health data to third parties associated with one of our panel providers, as per the provider’s privacy policy and only as required for the progression of your policy. We always carry out due diligence against all our third-party suppliers for the purposes of ensuring their compliance with data protection, maintaining adequate security of your data and ensuring they apply adequate data protection principles to the processing of the data we supply. We also make sure a legally binding contract (sometimes called a Data Processing Agreement or DPA) is also in place to protect your data.</li>
        <li>We may disclose your personal data to the police, regulatory bodies, legal advisors or similar third parties where we are under a legal duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our agreements; or to protect our rights, property, or safety of our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</li>
        </ol>
        </li>
        <li><strong>Cross-Border Data Transfers<br>
        </strong>We do not transfer personal data outside the EEA. Where this is required in the future, we will ensure that safeguards are in place so that such transfers comply with the relevant data protection legislation.</li>
        <li><strong>Data Security</strong>
        <ol>
        <li>Information you provide to us is stored on our secure infrastructure. We have implemented appropriate physical, technical and organisational measures designed to secure your information against accidental loss and unauthorised access, use, alteration or disclosure. In addition, we limit access to personal data to those employees, agents, contractors and other third parties that have a legitimate business need for such access.</li>
        <li>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your information transmitted to our site; any transmission is at your own risk.</li>
        </ol>
        </li>
        <li><strong>Retention<br>
        </strong>Unless stated elsewhere in this document or in any agreement or contract, we only store the data necessary for the purpose of having the data in the first place. We will keep this data for as long as it is lawful for us to do so (this may be for as long as you are a customer or because of a legal obligation to retain the information, whichever is the longest).</li>
        <li><strong>Your Rights<br>
        </strong>Under current data protection legislation in the UK, you have rights as an individual which you can exercise in relation to the data we store and process about you. You can find more information about your rights on the Information Commissioner’s website: <a href="https://ico.org.uk/for-the-public" target="_blank" rel="noopener">https://ico.org.uk/for-the-public</a>We may request specific information from you to help us confirm your identity. Data protection legislation may allow or require us to refuse to provide you with access to some or all the personal data that we hold about you or to comply with any requests made in accordance with your rights referred to above. If we cannot provide you with access to your personal data, or process any other request we receive, we will inform you of the reasons why, subject to any legal or regulatory restrictions.Please send any requests relating to the above to <a href="mailto:dataprotectionquries@protectline.co.uk">dataprotectionquries@protectline.co.uk</a> specifying your name and the action you would like us to undertake. Note that we reserve the right to charge a reasonable fee for administrative costs of complying with a request if it is excessive, manifestly unfounded or you request further copies of your data.<p></p>
        <ol>
        <li><u><strong>Keeping your data up to date<br>
        </strong></u>It is important that the personal data we hold about you is accurate and current. Please keep us informed if the personal data we hold about you changes.</li>
        <li><u><strong>Objecting to our processing and withdrawing consent<br>
        </strong></u>You have the right to object to the processing of your personal data in certain circumstances and to withdraw your consent to the processing of your personal data where this has been provided.<br>
        Where you have provided your consent to the collection, processing and transfer of your personal data, you may withdraw that consent at any time. To withdraw your consent please contact us at <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a>.</li>
        <li><u><strong>Erasure of your data (the “right to be forgotten”)<br>
        </strong></u>Under some circumstances you may request us to delete your data from our systems. Where this is possible (e.g. we don’t have any legal purpose for continuing to process your data) we will erase it from our systems.</li>
        <li><u><strong>Portability<br>
        </strong></u>Your right to portability allows you to request a machine-readable format of the data you supplied to us and associated service logs (where we store them).</li>
        <li><u><strong>Access to your data (a so-called Data Subject Access Request)<br>
        </strong></u>You have the right to ask us about what data we hold about you, how we process it and to ask us to provide you with a copy of the information, free of charge and within one calendar month of your request.<br>
        To make a request for any personal information we hold and process about you, we would prefer it if you could put it in writing or in an email to dataprotectionquries@protectline.co.uk. We will need to verify your identity before providing the information and where necessary may contact you further to ensure we understand what data you are requesting.</li>
        </ol>
        </li>
        <li><strong>Complaints<br>
        </strong>If you feel this privacy notice does not go far enough in explaining how we have used your personal data, we are happy to provide any additional information or explanation needed. Any requests for this should be sent to <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a>.If you want to make a complaint about the way we have processed your personal information, we’d rather you brought it to us in the first instance, but of course you can contact the Information Commissioner’s Office in their capacity as the statutory body that oversees data protection law – <a href="http://www.ico.org.uk/concerns" target="_blank" rel="noopener">www.ico.org.uk/concerns</a></li>
        <li><strong>Changes to our Privacy Policy<br>
        </strong>We reserve the right to update this privacy policy at any time, and any changes we make to our privacy policy will be posted on this page. We will notify you if there are any changes to this policy that materially affect how we collect, store or process your personal data. If we would like to use your previously collected personal data for different purposes than those we notified you about at the time of collection, we will provide you with notice and, where required by law, seek your consent, before using your personal data for a new or unrelated purpose. We may process your personal data without your knowledge or consent where required by applicable law or regulation.</li>
        <li><strong>Contact Us<br>
        </strong>We have appointed a Data Protection Officer to oversee compliance with this privacy policy. If you have any questions, comments or requests regarding this policy or how we use your personal data please contact our Data Protections Officer at <a href="mailto:dataprotectionqueries@protectline.co.uk">dataprotectionqueries@protectline.co.uk</a> This is in addition to your right to contact the Information Commissioners Office if you are unsatisfied with our response to any issues you raise at <a href="https://ico.org.uk/global/contact-us" target="_blank" rel="noopener">https://ico.org.uk/global/contact-us</a></li>
        </ol>
        </div></div>`
    }

    return <StandardPage postInfo={postInfo} post={post} />
}

export default Page